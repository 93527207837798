body{
	background-color: black !important;
	color: white !important;
}
ul.no-style{
	list-style: none;
}
.white{
	background-color: white !important;
	color: black !important;
}
.img-holder{
	.frame {
	    /*height: 160px;       equals max image height */
	    /*width: 160px;*/
	    @extend .small-6;
		@extend .medium-3;
		@extend .large-2;
	    white-space: nowrap;
	    margin-bottom: 1.1rem;
	    text-align: center;
	}

	.helper {
	    display: inline-block;
	    height: 100%;
	    vertical-align: middle;
	}

	img {
	    vertical-align: middle;
	    max-height: 160px;
	    margin-left: -5px;

	    margin-right: 0px;
	    /*padding-right: 20px;*/
	}
}
@media screen and (max-width: 639px) {
  .img-holder{
		.frame {
			/*border: solid 1px red;*/
			margin-bottom: 0.5rem;
		}
	}
}


/*Center top menu*/
nav.top-bar {
text-align:center;
background-color: #000;
}
section.top-bar-section {
	background-color: #000;
	display: inline-block;

	a{
		background-color: #000;
		/*color:white;*/
		font-size: 1.5rem;
		font-weight: 700;
	}

	a:hover {
		/*color: #cccccc;*/
		/*text-decoration: underline;*/
	}
}
@media screen and (max-width: 642px) {
  	section.top-bar-section a{
		font-size: 1rem;
	}
}

a.br-left, a.br-right{
	position: fixed;
	top:150px;
	img{
		opacity: 0.2;
		-webkit-transition: opacity 0.5s; /* Safari */
		transition: opacity 0.5s;

		&:hover{
			opacity: 1.0;
		}
	}
}







.br-left{
	left: 45px;
}
.br-right{
	right: 45px;
}











